<template>
<div class="tutorial full-screen">
    <PtyBack @back="back" />
    <div class="tutorial__content">
        <img src="../../assets/images/splash/6.png" style="width: 100%; margin-top: 20px;" alt="tutorial" />
        <h1>{{ $t('Tutorial.6.title') }}</h1>
        <p style="overflow-y: scroll; flex: 1;">
            {{ $t('Tutorial.6.caption') }}
        </p>
    </div>

    <pty-button class="sticked-bottom" animate extra-shadow block :loading="loading" @click="next">{{ $t('Common.next') }}</pty-button>
</div>
</template>

<script>
// @ts-ignore
import * as Sentry from '@sentry/vue';

import PtyButton from '@/components/PtyButton.vue';
import PtyBack from '@/components/PtyBack.vue';
export default {
    name: 'Tutorial5',
    components: {
        PtyButton,
        PtyBack
    },
    data: () => ({
        loading: false
    }),

    methods: {
        async hasCameraPermission() {
            if(navigator.permissions){
                const cameraPex = await navigator.permissions.query({
                    name: 'camera'
                });
                if (cameraPex.state !== 'granted') {
                    return false;
                } else {
                    return true;
                }
            } else {
                // try with getUserMedia because navigator.permissions is not supported in android webview
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ video: true }); 
                    stream.getTracks().forEach(track => track.stop());
                    return true;
                } catch (error) {
                    return false;
                }
            }
        },

        async hasLocationPermission(){
            const locationResult = await this.$getLocation();
            if(locationResult != null){
                return true;
            } else {
                return false;
            }
        },

        async next() {
            try {
                const hasLocationPermission = await this.hasLocationPermission();
                if (!hasLocationPermission) {
                    this.$router.push({ name: 'LocationPermission' });
                    return;
                }

                const hasCameraPermission = await this.hasCameraPermission();
                if (!hasCameraPermission) {
                    this.$router.push({ name: 'CameraPermission' });
                    return;
                }

                // go to OnBoarding
                this.$router.push({ name: 'OnBoarding' });
            } catch (error) {
                Sentry.captureException(error);

                console.error('Error:', error);
                this.$router.push({ name: 'LocationPermission' });
            }
        },

        back() {
            this.$router.push({ name: 'Tutorial5' });
        }
    }
}
</script>

<style scoped>
.tutorial {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 1.5rem;
    background: #82D5D9;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    

}

.tutorial__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    height: calc(100% - 100px);
}

.tutorial h1 {
    color: #183B3D;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
}

.tutorial p {
    color: #183B3D;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}
</style>
